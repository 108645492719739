import { getDataMapper, mapField, formatISO } from "../../../lib";
export const mapHumanToServer = getDataMapper({
    id: mapField("id", Number),
    user_id: mapField("userId", Number),
    guid: mapField("guid", String),
    number: mapField("number", Number),
    surname: mapField("surname"),
    name: mapField("name"),
    patro: mapField("patro"),
    fio: mapField("fio"),
    short_fio: mapField("shortFio"),
    avatar_fio: mapField("avatarFio"),
    avatar: mapField("avatar"),
    birthday: mapField("birthday", formatISO),
    male: mapField("isMale", Boolean),
    is_admin: mapField("isAdmin", Boolean),
    is_director: mapField("isDirector", Boolean),
    is_employee: mapField("isEmployee", Boolean),
    is_client: mapField("isClient", Boolean),
    passport_serial: mapField("passportSerial"),
    passport_number: mapField("passportNumber"),
    passport_take: mapField("passportTake"),
    passport_code: mapField("passportCode"),
    passport_take_date: mapField("passportTakeDate"),
    vatin: mapField("vatin"),
    sms_flag: mapField("smsFlag", Boolean, { applyToNil: true }),
});
