import { makeHttpRequest } from "@/shared";

export const deleteRepairStatuses = async (ids) => {
    return (
        await makeHttpRequest({
            url: "/repair/state/delete-multiple",
            method: "DELETE",
            patams: { items: ids },
        })
    ).data;
};
