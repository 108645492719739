<script setup>
import { Tippy } from "vue-tippy";

import { BaseButton, BaseTooltip, IconPlus } from "@/shared";

import BaseDropdownMenu from "./BaseDropdownMenu.vue";
import dropdownProps from "./config/dropdownProps";

const app = document.body;

defineProps({ ...dropdownProps });
</script>

<template>
    <Tippy
        class="base-dropdown"
        v-bind="$attrs"
        :hide-on-click="true"
        interactive
        trigger="click"
        placement="bottom"
        :offset="[0, 5]"
        :duration="0"
        :delay="0"
        :arrow="false"
        :append-to="app"
        :popper-options="{
            strategy: 'fixed',
        }"
        :zIndex="zIndex"
    >
        <BaseTooltip :content="tooltipText">
            <BaseButton
                :size="buttonSize"
                :type="buttonType"
                :variant="buttonVariant"
                class="base-dropdown__button"
                :class="buttonClass"
            >
                <slot name="button-content">
                    <IconPlus />
                </slot>
            </BaseButton>
        </BaseTooltip>
        <template #content="{ hide }">
            <slot name="dropdown-menu" :hide="hide">
                <BaseDropdownMenu
                    :right="dropdownRight"
                    :maxHeight="dropdownMaxHeight"
                    :links="links"
                    :has-cross="hasCross"
                    @close="hide"
                >
                    <slot name="dropdown-content" :links="links" />
                </BaseDropdownMenu>
            </slot>
        </template>
    </Tippy>
</template>

<style lang="scss" scoped>
.base-dropdown {
    position: relative;

    svg,
    :slotted(svg) {
        display: none;

        &:only-child {
            display: block;
        }

        @media (max-width: 425px) {
            display: block;
        }
    }

    &__item {
        display: inline-flex;

        align-items: center;

        font-size: var(--default-text-size);
    }
}
</style>
